.App {
  text-align: center;
  background-color: #2d3748; /* Dark gray background */
  color: white; /* White text */
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the app takes full height */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2aded4; /* Green background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  flex-grow: 1; /* Ensures the header content fills the screen */
}

.App-link {
  color: #f7fafc; /* Light gray for the link text */
  text-decoration: none;
  font-weight: bold;
}

.App-link:hover {
  color: #edf2f7; /* Light gray hover color */
  text-decoration: underline;
}

/* Styling for main content */
.main-content {
  flex-grow: 1; /* Ensures the content grows to take available space */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Footer Styling */
footer {
  background-color: #333; /* Dark footer */
  color: white; /* Light text */
  padding: 10px 0;
  text-align: center;
  margin-top: auto; /* Pushes the footer to the bottom */
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  scroll-behavior: smooth;
}

